var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('ValidationProvider',{attrs:{"name":"titulo","rules":_vm.formRules.idtmovcaja},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tipoMovimiento,"item-value":"idtmovcaja","item-text":"descripcion","label":"Tipo de movimiento","clearable":"","error-messages":errors},on:{"change":_vm.changeTipoMovimiento},model:{value:(_vm.form.idtmovcaja),callback:function ($$v) {_vm.$set(_vm.form, "idtmovcaja", $$v)},expression:"form.idtmovcaja"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"fmovimiento","rules":_vm.formRules.fmovimiento},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-picker',{attrs:{"title":"Fecha","error-messages":errors},model:{value:(_vm.form.fmovimiento),callback:function ($$v) {_vm.$set(_vm.form, "fmovimiento", $$v)},expression:"form.fmovimiento"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"importe","rules":_vm.formRules.importe},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Importe","clearable":"","type":"number","error-messages":errors,"suffix":"€"},model:{value:(_vm.form.importe),callback:function ($$v) {_vm.$set(_vm.form, "importe", _vm._n($$v))},expression:"form.importe"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"formapago","rules":_vm.formRules.formapago},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.formasPago,"item-text":"descripcion","return-object":"","label":"Forma de pago","clearable":"","error-messages":errors},on:{"change":function($event){return _vm.formaPagoSelected(_vm.form.formapago)}},model:{value:(_vm.form.formapago),callback:function ($$v) {_vm.$set(_vm.form, "formapago", $$v)},expression:"form.formapago"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"idcaja","rules":_vm.formRules.idcaja},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.cajas,"item-value":"idcaja","item-text":"descripcion","label":"Caja","clearable":"","error-messages":errors,"disabled":!_vm.form.formapago},model:{value:(_vm.form.idcaja),callback:function ($$v) {_vm.$set(_vm.form, "idcaja", $$v)},expression:"form.idcaja"}})]}}])}),(_vm.isInsert)?_c('div',[_c('ValidationProvider',{attrs:{"name":"concepto_movcaja","rules":_vm.formRules.concepto_movcaja},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.conceptoMovcajaFiltradoPorTipo,"item-text":"descripcion","return-object":"","label":"Tipo de concepto","clearable":"","error-messages":errors},on:{"change":_vm.changeConceptoMovcaja},model:{value:(_vm.form.concepto_movcaja),callback:function ($$v) {_vm.$set(_vm.form, "concepto_movcaja", $$v)},expression:"form.concepto_movcaja"}})]}}],null,false,1734596041)})],1):_vm._e(),_c('ValidationProvider',{attrs:{"name":"concepto","rules":_vm.formRules.concepto},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Concepto"},model:{value:(_vm.form.concepto),callback:function ($$v) {_vm.$set(_vm.form, "concepto", $$v)},expression:"form.concepto"}})]}}])}),_c('b10-textarea',{attrs:{"label":"Observaciones"},model:{value:(_vm.form.observaciones),callback:function ($$v) {_vm.$set(_vm.form, "observaciones", $$v)},expression:"form.observaciones"}}),(_vm.isInsert && _vm.hasPermAdjuntos)?_c('div',[(_vm.isInsert)?_c('ValidationProvider',{attrs:{"name":"ficheros","rules":_vm.formRules.ficheros},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"label":"Ficheros","show-size":"","prepend-icon":"attach_file","chips":"","multiple":"","accept":_vm.stringTypeExtensions,"error-messages":errors,"append-outer-icon":_vm.$vuetify.icons.values.camera,"disabled":_vm.capturedImage},on:{"change":_vm.filesChange,"click:append-outer":_vm.clickCamera},model:{value:(_vm.form.ficheros),callback:function ($$v) {_vm.$set(_vm.form, "ficheros", $$v)},expression:"form.ficheros"}})]}}],null,false,3119216628)}):_vm._e(),_c('b10-camera',{attrs:{"showing":_vm.showingCamera,"device-id":_vm.devicesCameraDeviceId},on:{"update:showing":function($event){_vm.showingCamera=$event},"click-shutter":_vm.clickShutter,"change-deviceid":_vm.changeCamera}}),_c('ValidationProvider',{attrs:{"name":"idtfichero","rules":_vm.formRules.idtfichero},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"disabled":_vm.form.ficheros.length === 0,"items":_vm.tficheros,"item-value":"idtfichero","item-text":"descripcion","label":"Tipo de fichero","clearable":"","error-messages":errors},model:{value:(_vm.form.idtfichero),callback:function ($$v) {_vm.$set(_vm.form, "idtfichero", $$v)},expression:"form.idtfichero"}})]}}],null,false,3287172168)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }