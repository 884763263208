import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectLookups (Vue, idempleado, idsParametros, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    const apiFilterEmpleadoCaja = new APIFilter()
    apiFilterEmpleadoCaja
      .addGT('estado', 0)
      .addExact('idempleado', idempleado)
    apiCalls.push({ name: 'empleadoCajaSelect', method: 'empleadoCaja.select', params: { filter: apiFilterEmpleadoCaja } })
    const apiFilterFormaPago = new APIFilter()
    apiFilterFormaPago
      .addGT('estado', 0)
      .addExact('movilidad', 1)
    apiCalls.push({ name: 'formaPagoSelect', method: 'formaPago.select', params: { filter: apiFilterFormaPago } })
    const apiFilterTmovcaja = new APIFilter()
    apiFilterTmovcaja
      .addGT('estado', 0)
      .addExact('parausuario', 1)
    apiCalls.push({ name: 'tmovcajaSelect', method: 'tmovcaja.select', params: { filter: apiFilterTmovcaja } })
    const apiFilterConceptoMovcaja = new APIFilter()
    apiFilterConceptoMovcaja
      .addGT('estado', 0)
      .addExact('visible_movilidad', 1)
    apiCalls.push({ name: 'conceptoMovcajaSelect', method: 'conceptoMovcaja.select', params: { filter: apiFilterConceptoMovcaja } })
    const apiFilterParametros = new APIFilter()
    apiFilterParametros.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParametros } })
    // Ficheros
    const apiFilterFicheros = new APIFilter()
    apiFilterFicheros.addGT('estado', 0)
    const apiFilterVisualizacion = new APIFilter()
    apiFilterVisualizacion.setOperator(API_FILTER_OPERATORS.OR)
    if (esTecnico) {
      apiFilterVisualizacion.addExact('visible_movilidad_sat', esTecnico)
    }
    if (esComercial) {
      apiFilterVisualizacion.addExact('visible_movilidad_vigilancia', esComercial)
    }
    if (esVigilante) {
      apiFilterVisualizacion.addExact('visible_movilidad_sat', esVigilante)
    }
    apiFilterFicheros.addNestedFilter(apiFilterVisualizacion)
    apiCalls.push({ name: 'selectTfichero', method: 'tfichero.select', params: { filter: apiFilterFicheros } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idmovcaja', id)
    const resp = await Vue.$api.call('movcaja.selectView', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
