<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="titulo"
      :rules="formRules.idtmovcaja"
    >
      <b10-autocomplete
        v-model="form.idtmovcaja"
        :items="tipoMovimiento"
        item-value="idtmovcaja"
        item-text="descripcion"
        label="Tipo de movimiento"
        clearable
        :error-messages="errors"
        @change="changeTipoMovimiento"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="fmovimiento"
      :rules="formRules.fmovimiento"
    >
      <b10-date-picker
        v-model="form.fmovimiento"
        title="Fecha"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="importe"
      :rules="formRules.importe"
    >
      <v-text-field
        v-model.number="form.importe"
        label="Importe"
        clearable
        type="number"
        :error-messages="errors"
        suffix="€"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="formapago"
      :rules="formRules.formapago"
    >
      <b10-autocomplete
        v-model="form.formapago"
        :items="formasPago"
        item-text="descripcion"
        return-object
        label="Forma de pago"
        clearable
        :error-messages="errors"
        @change="formaPagoSelected(form.formapago)"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="idcaja"
      :rules="formRules.idcaja"
    >
      <b10-autocomplete
        v-model="form.idcaja"
        :items="cajas"
        item-value="idcaja"
        item-text="descripcion"
        label="Caja"
        clearable
        :error-messages="errors"
        :disabled="!form.formapago"
      />
    </ValidationProvider>
    <div
      v-if="isInsert"
    >
      <ValidationProvider
        v-slot="{ errors }"
        name="concepto_movcaja"
        :rules="formRules.concepto_movcaja"
      >
        <b10-autocomplete
          v-model="form.concepto_movcaja"
          :items="conceptoMovcajaFiltradoPorTipo"
          item-text="descripcion"
          return-object
          label="Tipo de concepto"
          clearable
          :error-messages="errors"
          @change="changeConceptoMovcaja"
        />
      </ValidationProvider>
    </div>
    <ValidationProvider
      v-slot="{ errors }"
      name="concepto"
      :rules="formRules.concepto"
    >
      <v-text-field
        v-model="form.concepto"
        :error-messages="errors"
        label="Concepto"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
    <div
      v-if="isInsert && hasPermAdjuntos"
    >
      <ValidationProvider
        v-if="isInsert"
        v-slot="{ errors }"
        name="ficheros"
        :rules="formRules.ficheros"
      >
        <v-file-input
          v-model="form.ficheros"
          label="Ficheros"
          show-size
          prepend-icon="attach_file"
          chips
          multiple
          :accept="stringTypeExtensions"
          :error-messages="errors"
          :append-outer-icon="$vuetify.icons.values.camera"
          :disabled="capturedImage"
          @change="filesChange"
          @click:append-outer="clickCamera"
        />
      </ValidationProvider>
      <b10-camera
        :showing.sync="showingCamera"
        :device-id="devicesCameraDeviceId"
        @click-shutter="clickShutter"
        @change-deviceid="changeCamera"
      />
      <ValidationProvider
        v-slot="{ errors }"
        name="idtfichero"
        :rules="formRules.idtfichero"
      >
        <b10-autocomplete
          v-model="form.idtfichero"
          :disabled="form.ficheros.length === 0"
          :items="tficheros"
          item-value="idtfichero"
          item-text="descripcion"
          label="Tipo de fichero"
          clearable
          :error-messages="errors"
        />
      </ValidationProvider>
    </div>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './MovimientoCajaFormData'
import { PARAMETRO, TIPOS_MOVIMIENTO_CAJA, TYPE_EXTENSIONS } from '@/utils/consts'
import { currentDate } from '@/utils/date'
import  { extend } from 'vee-validate'
import { sync } from 'vuex-pathify'
import _ from '@/utils/lodash'
import { size as fileSizeValidation } from 'vee-validate/dist/rules'

export default {
  mixins: [formMixin],
  props: {
    usuarioIdempleado: {
      type: [Number, String],
      default: null
    },
    idproyectoContable: {
      type: [Number, String],
      default: null
    },
    idparteTrabajo: {
      type: [Number, String],
      default: null
    },
    esTecnico: {
      type: Boolean,
    },
    esComercial: {
      type: Boolean,
    },
    esVigilante: {
      type: Boolean,
    },
    hasPermAdjuntos: {
      type: Boolean,
    },
  },
  data () {
    return {
      form: {
        formapago: null,
        idcaja: null,
        idtmovcaja: null,
        importe: null,
        fmovimiento: currentDate(),
        concepto: null,
        concepto_movcaja: null,
        observaciones: null,
        tipo: null,
        idproyecto_contable: null,
        // adjuntos
        ficheros: [],
        idtfichero: null,
        idproyecto_contable: null,
      },
      formRules: {
        idtmovcaja: { required: true },
        importe: { required: true },
        formapago: { required: true },
        idcaja: { required: true },
        fmovimiento: { required: true },
        concepto_movcaja: { required: !this.id }, // lo ideal sería poner this.isInsert, pero no sé por qué no toma bien el valor.
        concepto: { required: true },
        ficheros: { tamanioFicheroAdjunto: true, tipoFicheroValido: true },
        idtfichero: { required_if: { target: 'ficheros' } },
      },
      stringTypeExtensions: `${TYPE_EXTENSIONS.join(',')}`,
      showingCamera: false,
      capturedImage: false,
      formasPago: [],
      cajas: [],
      cajasEmpleado: [],
      tipoMovimiento: [],
      conceptoMovcaja: [],
      conceptoMovcajaFiltradoPorTipo: [],
      tficheros: [],
    }
  },
  computed: {
    'devicesCameraDeviceId': sync('devices/cameraDeviceId'),
  },
  async created () {
    extend('tamanioFicheroAdjunto', {
      message: 'El fichero es demasiado grande',
      validate: async (value) => {
        if (this.tamanioMaxAdjuntoMB > 0) {
          return fileSizeValidation.validate(value, { size: this.tamanioMaxAdjuntoMB * 1024 })
        } else {
          return true
        }
      },
    })
    extend('tipoFicheroValido', {
      message: 'Tipo de fichero no permitido',
      validate: (value) => {
        return _.filter(value, (fileValue) => !TYPE_EXTENSIONS.includes(fileValue.type)).length === 0
      }
    })
    const parametrosNecesarios = [
      PARAMETRO.parametros.TAMANIO_MAX_ADJUNTOS_MB,
    ]
    const resp = await Data.selectLookups(
      this,
      this.usuarioIdempleado,
      parametrosNecesarios,
      this.esTecnico,
      this.esComercial,
      this.esVigilante
    )
    const parametroMaxAdjuntoMB = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TAMANIO_MAX_ADJUNTOS_MB })
    if (parametroMaxAdjuntoMB) {
      this.tamanioMaxAdjuntoMB = parseInt(parametroMaxAdjuntoMB.valor)
    }
    this.tficheros = resp.data.selectTfichero.result.dataset
    this.cajasEmpleado = resp.data.empleadoCajaSelect.result.dataset
    // Las cajas están en dos objetos porque se van filtrando por tipo cuando cambio de forma de pago. Para no tener que hacer el select de nuevo.
    this.cajas = this.cajasEmpleado
    this.formasPago = resp.data.formaPagoSelect.result.dataset
    if (this.formasPago.length === 1) {
      this.form.formapago = this.formasPago[0]
      // forzar evento
      this.formaPagoSelected(this.formasPago[0])
    }
    this.conceptoMovcaja = resp.data.conceptoMovcajaSelect.result.dataset
    if (!this.isInsert) {
      this.conceptoMovcajaFiltradoPorTipo = this.conceptoMovcaja
    }
    this.tipoMovimiento = resp.data.tmovcajaSelect.result.dataset
    this.$set(this.form, 'idtmovcaja', parseInt(TIPOS_MOVIMIENTO_CAJA.por_defecto))
    // forzar evento
    this.changeTipoMovimiento(this.form.idtmovcaja)
    if (this.idproyectoContable) {
      this.$set(this.form, 'idproyecto_contable', parseInt(this.idproyectoContable))
    }
    if (this.idparteTrabajo) {
      this.$set(this.form, 'observaciones', `Del parte de trabajo Nº ${this.idparteTrabajo}`)
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
      formData.formapago = _.find(this.formasPago, {'idforma_pago': formData.idformapago})
      this.$set(this.form, 'concepto_movcaja', null)
      this.changeTipoMovimiento(formData.idtmovcaja)
      const importe = parseInt(formData.idtmovcaja) === TIPOS_MOVIMIENTO_CAJA.tipos.gastoManual ? formData.importe *-1 : formData.importe
      this.$set(this.form, 'importe', importe)
    },
    formaPagoSelected (formapago) {
      // La forma de pago tiene el tipo de caja disponible, hay que mostrar sólo las cajas permitidas por esa forma de pago
      if (formapago?.idtcaja) {
        const cajasPorTipo = _.filter(this.cajasEmpleado, {'idtcaja': formapago.idtcaja})
        if (cajasPorTipo.length > 0) {
          this.cajas = cajasPorTipo
        } else if (cajasPorTipo.length === 0) {
          this.cajas = this.cajasEmpleado
        }
        if (cajasPorTipo.length === 1) {
          this.$set(this.form, 'idcaja', parseInt(cajasPorTipo[0].idcaja))
        }
      } else {
        this.$set(this.form, 'idcaja', null)
      }
    },
    changeTipoMovimiento (value) {
      if (this.isInsert && value){
        this.conceptoMovcajaFiltradoPorTipo = _.filter(this.conceptoMovcaja, {'idtmovcaja': value})
        if (this.conceptoMovcajaFiltradoPorTipo.length === 1) {
          const idconceptoMovcaja = this.conceptoMovcajaFiltradoPorTipo[0].idconcepto_movcaja
          this.$set(this.form, 'concepto_movcaja', _.find(this.conceptoMovcajaFiltradoPorTipo, { 'idconcepto_movcaja': idconceptoMovcaja}))
          this.changeConceptoMovcaja(this.conceptoMovcajaFiltradoPorTipo[0])
        } else {
          this.changeConceptoMovcaja(null)
        }
      }
    },
    changeConceptoMovcaja (value) {
      if (value && this.isInsert && !this.form.concepto) {
        this.$set(this.form, 'concepto', value.descripcion)
      }
    },
    filesChange (files) {
      this.$set(this.form, 'ficheros', files)
    },
    clickCamera () {
      this.showingCamera = true
    },
    clickShutter (imgFile) {
      this.filesChange([imgFile])
      this.capturedImage = true
    },
    changeCamera (value) {
      this.devicesCameraDeviceId = value
    },
  },
}
</script>
