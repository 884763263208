import { TIPOS_MOVIMIENTO_CAJA } from '@/utils/consts'
import { TABLA } from '@/utils/consts'
import { ReadFileBase64 } from '@/utils/files'

export default {
  async insert (Vue, formData, usuario) {
    // Si es un gasto tengo que meter el importe negativo
    const importe = parseInt(formData.idtmovcaja) === TIPOS_MOVIMIENTO_CAJA.tipos.gastoManual ? formData.importe *-1 : formData.importe
    let apiCalls = []
    const resp = await Vue.$api.call(
      'movcaja.insert',
      {
        values: {
          idformapago: formData.formapago.idforma_pago,
          idcaja: formData.idcaja,
          idtmovcaja: formData.idtmovcaja,
          importe: importe,
          fmovimiento: formData.fmovimiento,
          faplicacion: formData.fmovimiento,
          concepto: formData.concepto,
          observaciones: formData.observaciones,
          idempleado: usuario.idempleado,
          tipo: TIPOS_MOVIMIENTO_CAJA.tipo_manual_usuario,
          idproyecto_contable: formData.idproyecto_contable,
          iddelegacion: usuario.iddelegacion,
          idproyecto_contable: formData.idproyecto_contable,
          imputa_al_proyecto: formData.idproyecto_contable !== null
        },
      },
    )
    for (const file of formData.ficheros) {
      apiCalls.push({
        name: 'ficheroInsert',
        method: 'fichero.insert',
        params: {
          values: {
            data: await ReadFileBase64(file),
            nombre_fichero: file.name,
            idtabla: TABLA.movcaja.idtabla,
            id: resp.data.result.dataset[0].idmovcaja,
            idtfichero: formData.idtfichero,
            observaciones: null,
          },
        },
      })
    }
    await Vue.$api.batchCall(apiCalls)
    return resp.data.result.dataset[0]
  },
}
