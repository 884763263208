<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <movimiento-caja-form
          :usuario-idempleado="usuario.idempleado"
          :es-tecnico="!!usuario.idtecnico"
          :es-comercial="!!usuario.idcomercial"
          :es-vigilante="!!usuario.idvigilante"
          :idproyecto-contable="routeQuery.idproyecto_contable"
          :idparte-trabajo="routeQuery.idparte_trabajo"
          :has-perm-adjuntos="hasInsertPerm(permissions.movimientosCajaAdjunto.id)"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import MovimientoCajaForm from '../components/MovimientoCajaForm'
import Data from './MovimientoCajaAddData'
import { get } from 'vuex-pathify'

export default {
  components: {
    MovimientoCajaForm
  },
  mixins: [formPageMixin],
  props: {
    viewRoute: {
      type: String,
      default: '',
    },
  },
  computed: {
    usuario: get('usuario'),
  },
  async created () {
    // routeQuery: idproyectoContable para añadirlo al registro, iparteTrabajo: para ponerlo en las observaciones
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir movimiento de caja'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      const inserted = await Data.insert(
        this,
        this.formData,
        this.usuario,
      )
      this.$appRouter.replace({
        name: this.viewRoute,
        params: {
          idmovcaja: inserted.idmovcaja,
        },
      })
    },
  },
}
</script>
